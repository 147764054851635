import React from 'react'
import About from './About'
import Portfolio from './Portfolio'
import Contact from './Contact'

function Home(){
    return(
        <><Portfolio /><Contact /></>        
    )
}
export default Home