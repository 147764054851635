import React, { useEffect } from 'react'
import PortfolioItem from './PortfolioItem'
import PortfolioData from './PortfolioData'

function Portfolio(){


  const portfolioItems = PortfolioData.map(item =>
    <PortfolioItem key ={item.name} name ={item.name} client ={item.client} url={item.url} imgMin ={item.imgMin} imgUrl ={item.imgUrl} imgAlt ={item.imgAlt} description ={item.description} techs={item.techs} />
  )

  return(
    <div className="portfolioWrapper is-loading">
      {portfolioItems}
    </div>
  )
}

export default Portfolio