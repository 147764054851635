import React from 'react'
import LightGallery from 'lightgallery/react'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-thumbnail.css'

function PortfolioItem(props){

	function generateImg(props){
		let imgUrlList = props.imgUrl
		if (imgUrlList.length > 0){
			return imgUrlList.map(function(item, i){
				return(
					<a key={i} className='gallery-item' data-src={item} data-sub-html={props.description} >
        				<img key={i} src={item} alt={props.imgAlt} />
    				</a>
				)
			})
		}else{
			return []
		}
	}
	
	return(
		<article className='PortfolioItem'>
			<span className='project'>
				<h3>
					<a href={props.url} target="_blank" rel="noopener noreferrer">{props.client}</a>
				</h3>
				<a href={props.url} target="_blank" rel="noopener noreferrer">{props.name}</a>
			</span>
			<LightGallery mode="lg-fade">
				{generateImg(props)}
			</LightGallery>

		</article>
	)
}

export default PortfolioItem