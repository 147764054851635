import React from 'react'
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import Home from './Home'
import About from './About'
import Contact from './Contact'


function Main(){

    const tilesZi = 1150;
    const tilesZ = [...Array(tilesZi)].map((e, i) =>
        <span className='tile' id={'tile'+i} loc={'#'+i} state='hidden' key={i}> </span>
    );      

    return(<>
        <section id="main">
            <div className='border'></div>
            <Router>
                <div>
                    
                    <div className="space"></div>
                    <Routes>
                        <Route path="/*">
                            <Route className="home content" index element={<Home />} />
                            <Route path="about" className="about content" element={<><Contact /><About /></>} />
                        </Route>
                    </Routes>
                    
                </div>
            </Router>
            <div className="tiles">
                {tilesZ}
            </div>
            <div className='border'></div>
        </section>
    </>)
}


export default Main