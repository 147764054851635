import React from 'react'

function About(){
    return(
        <section id="about" className="about tab">
            
            <input type="checkbox" id="check-about" checked="true"/>
            {/*'<label class="tab-label" for="check-about"></label>'*/}
            <div className="tab-content">

                <ul className="cv career">
                    <li><span>2019 / 2020</span>Infographiste développeur pour Illumination Mac Guff</li>
                    <li><span>2015 / 2018</span>Motion Designer / Développeur web pour CAPA Télévision</li>
                    <li><span>2013 / 2014</span>Graphiste / Développeur web pour MPH Production</li>
                </ul>
                <ul className="cv school">
                    <li><span>2019 / 2020</span>Institut Artline, Paris CQP Expert Technique en Création Numérique</li>
                    <li><span>2012 / 2013</span>Université Paris 8 - Vincennes / Saint-Denis, Paris Master Création & Édition Numériques</li>
                    <li><span>2011 / 2012</span>Université Paris Ouest - Nanterre / La Défense, Paris Licence Communication Audiovisuelle & Multimédia</li>
                    <li><span>2008 / 2011</span>Institut Universitaire de Technologie, Michel de Montaigne, Bordeaux DUT Métiers du Multimédia et de l’Internet exSRC</li>
                    <li><span>2007 / 2008</span>Faculté de Lettres, de Langues, d’Arts et de Sciences Humaines, La Rochelle L1 Langues Ètrangères Appliquées, Anglais / Chinois</li>
                    <li><span>2006 / 2007</span>LISAA Nantes Mise à Niveau en Arts Appliqués</li>
                </ul>
                <ul>
                    <li>AFPS - Anglais Courant / Chinois Scolaire - BAFA</li>
                </ul>
                <ul className="cv career hidden">
                    <li><span>2019 / 2020</span>Infographiste développeur pour Illumination Mac Guff</li>
                    <li><span>2015 / 2018</span>Développeur web pour CAPA Télévision</li>
                    <li><span>Septembre / Octobre 2014</span>Conception Web pour le festival PanamAnim</li>
                    <li><span>Août / Septembre 2014</span>Conception Web pour Delapost Paris</li>
                    <li><span>2013 / 2014</span>Graphiste pour MPH Production</li>
                    <li><span>Septembre 2013</span>Graphiste pour Everlife Paris</li>
                    <li><span>Avril / Juillet 2012</span>Assistant de postproduction pour Delapost Paris <em>Stage</em></li> 
                    <li><span>Juillet / Août 2010</span>Assistant de postproduction pour TV7<em>Stage</em></li>
                </ul>
                <ul className="cv school  hidden">
                    <li><span>2019 / 2020</span>Institut Artline, Paris CQP Expert Technique en Création Numérique</li>
                    <li><span>2012 / 2013</span>Université Paris 8 - Vincennes / Saint-Denis, Paris Master Création & Édition Numériques</li>
                    <li><span>2011 / 2012</span>Université Paris Ouest - Nanterre / La Défense, Paris Licence Communication Audiovisuelle & Multimédia</li>
                    <li><span>Mai / Juillet 2011</span>Graphiste pour Mode.fr <em>Stage</em></li> 
                    <li><span>Juillet / Août 2010</span>Conception web pour Gianna et Moi <em>Stage</em></li>
                    <li><span>2008 / 2011</span>Institut Universitaire de Technologie, Michel de Montaigne, Bordeaux DUT Métiers du Multimédia et de l’Internet exSRC</li>
                    <li><span>2007 / 2008</span>Faculté de Lettres, de Langues, d’Arts et de Sciences Humaines, La Rochelle L1 Langues Ètrangères Appliquées, Anglais / Chinois</li>
                    <li><span>2006 / 2007</span>LISAA Nantes Mise à Niveau en Arts Appliqués</li>
                    <li>Baccalauréat Économique et Social</li>
                </ul>
            </div>
        </section>
    )
}
export default About