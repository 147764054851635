import React from 'react';
import LogoBox from './components/LogoBox'
import Main from './components/Main'
import Footer from './components/Footer'
import './App.css';

function App() {
  return(
    <>
      <main>
        <header>
          <LogoBox />
        </header>
        <Main />
      </main>
      <Footer />
    </>
  )
}

export default App;
