import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import WebFont from 'webfontloader';



const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);


WebFont.load({
  custom: {
    families: ['geometria', 'rubik:i4,n1,i1', 'eurocine'],
    urls:['/assets/fonts/stylesheet.css']
  }
});