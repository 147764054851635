import React from 'react'

function LogoBox() {

  setTimeout(() => {
    document.getElementById('logobox').classList.remove('active');
  }, "3250")

  return (
    <a id="logobox" className="active" href="#">
      <div id="corner-logobox"></div>
      <div className="author">
        <span>Léo Menant</span>
      </div>
      <div className="baseline">
        <span>Développement Web</span><span>Création Graphique</span>
      </div>
      {
      <svg className="cube-svg" viewBox="0 0 272 272">
        <g id="cubes" className="closed">
          <g className="cube start" id="cube4">
            <polygon className="face" points="138.46 98.96 99.35 121.53 99.35 166.67 138.46 189.24 177.56 166.67 177.56 121.53 138.46 98.96" />
            <polygon className="face" points="177.56 121.53 138.46 144.1 138.46 189.24 177.56 166.67 177.56 121.53" />
            <polygon className="face" points="99.84 121.53 138.94 98.96 178.05 121.53 138.94 144.1 99.84 121.53" />
          </g>
          <g className="cube start" id="cube3">
            <polygon className="face" points="139.9 97.1 100.1 120.1 100.1 166 139.9 189 179.6 166 179.6 120.1 139.9 97.1" />
            <polygon className="face" points="179.6 120.1 139.9 143.1 139.9 189 179.6 166 179.6 120.1" />
            <polygon className="face" points="101.2 120.1 141 97.1 180.8 120.1 141 143.1 101.2 120.1" />
          </g>
          <g className="cube start" id="cube2">
            <polygon className="face" points="139.3 97.2 99.6 120.1 99.6 166 139.3 188.9 179 166 179 120.1 139.3 97.2" />
            <polygon className="face" points="179 120.1 139.3 143.1 139.3 188.9 179 166 179 120.1" />
            <polygon className="face" points="99.6 120.1 139.3 97.2 179 120.1 139.3 143.1 99.6 120.1" />
          </g>
          <g className="cube start" id="cube1">
            <polygon className="face" points="139.3 97.2 99.5 120.1 99.5 166.1 139.3 189 179.1 166.1 179.1 120.1 139.3 97.2" />
            <polygon className="face" points="179.1 120.1 139.3 143.1 139.3 189 179.1 166.1 179.1 120.1" />
            <polygon className="face" points="100.7 120.1 140.4 97.2 180.2 120.1 140.4 143.1 100.7 120.1" />
          </g>
        </g>
      </svg>
      }
      <svg id="lm0" viewBox="0 0 68.86 53.97"><polygon points="8.52 46.84 45.15 45.68 45.25 52.81 0 53.97 0.39 0 9.76 0.07 8.52 46.84"/><polygon points="47.77 21.39 36.65 39.7 31.35 39.67 22.35 24.01 21.94 39.7 14.9 39.8 14.4 5.57 20.33 5.51 34.04 26.79 49.65 4.87 55.02 4.75 57.43 51.55 50.3 51.65 47.77 21.39"/><rect x="61.6" y="44.4" width="7.25" height="7.25"/>
      </svg>
      <svg id="lm" viewBox="0 0 131 131">
        <g>
          <g>
            <rect/>
          </g>
          <g>
            <path d="M79.8,77.7c0-0.1,0-0.2,0-0.3c0-7.4,0.1-14.8,0.1-22.2c0-0.2,0.2-0.2,0.2-0.4l-2.3,2.7l-2.5,3.6l-2,3.2
              l-2,3.2c2.3,4.5,2.5,5.6,2.5,5.6s0.8,3.6-1.2,5.5c0,0-1.6,1.6-3.1,1.5c0,0-2.9,0.1-3.5-3.4c-0.4-2.4,1.1-5.5,1.1-5.5
              s1.1-2,1.1-1.9c0-0.1,1.2,1.9,1.2,1.9c0,0-2.3,3.5-1.5,5.5c1.4,3.6,3.2,0.4,3.2,0.4s0.4-0.9,0.2-1.8c-0.3-1.7-2-4.9-2-4.9
              c-0.2-0.4-1.6-2.4-1.6-2.3c0.2,0.2-2.3-3.6-2.3-3.7l-1.6-2.5l-1.4-2.3c0,0-2.8-4.5-3-4.5c0,2.3,0,4.6,0,6.9c0,0,2.1,0.4,3,1.8
              c3.5,5.9-3.1,8.5-3.1,8.5c-0.1,0,0,3.2,0,5.1l0.3,0.5l2.2,0c0,0.3,0,1.3,0,1.6l-6.5-0.1l0-1.6c0.7,0,1.5,0,2.2,0l0.3-0.4l0-4.9
              c-0.1,0-0.6,0-0.7,0l-10.7-0.1l0-0.3c1.2,0,1.1-1.4,1.1-1.4s0.1-13.1,0.1-15.8c0-0.8-0.1-2.8-1.4-2.7c0-0.2-0.1-0.2-0.2-0.2
              c0-0.2,0.1-0.2,0.2-0.2c5.1,0,10.2,0.1,15.3,0.1c0.3,0.2,2.3,3.4,2.3,3.4l5.2,8.6c0,0,1.3,1.8,1.3,1.9c0,0.1,1.3-1.9,1.4-1.9
              l5.9-8.9c0,0,1.2-1.5,1.3-1.7c0.2-0.3,0.6-0.8,0.6-1.1c0-0.1,6.3,0,7,0c0,0.3,0,0.5,0,0.8c-0.6,0-0.6,0.6-0.6,0.6
              c-0.4,0-0.9,0-1.4,0c-1,0-1,1.1-1,1.1c0,7.7-0.1,15.3-0.1,23.1c0,0,0.3,0.4,0.4,0.4c0.1,0,0.3,0,0.4,0c0.4,0,0.9,0,1.3,0
              c0.3,0,0.6,0,0.8,0l0,0.8l0,0.9l-9.1-0.1l0-0.9l0-0.7c0.7,0,1.4,0,2,0L79.8,77.7 M51.4,55c0,5-0.1,9.9-0.1,14.9
              c0,0.2,0.2,0.2,0.2,0.4c0,0,4.7,0.7,6.5,0.2c0,0,0.1-3.8,0.1-4.7L58,64.3c-1.2-0.2-1.7,1.4-1.4,2c0.3,0.6,1.3,0.3,1.3,0.3
              l-0.1,2.2c0,0-3.4,0.3-3.3-3.3c0-2.8,3.6-3.4,3.6-3.4c0-2.5,0-5,0-7.5c-2.2,0-4.5,0-6.7,0C51.4,54.6,51.4,54.8,51.4,55 M59.4,70.1
              c1.3-0.2,2.1-1.4,2-3.2c0,0-0.2-2.5-2.1-2.5L59.4,70.1z"/>
          </g>
        </g>
      </svg>
    </a>
  )
}

export default LogoBox