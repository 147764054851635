import fukushimaImgMin from '../assets/img/portfolio/min/fukushima/kotoba-fukushima.jpg'
import fukushimaImg1 from '../assets/img/portfolio/fukushima/kotoba-fukushima.jpg'
import fukushimaImg2 from '../assets/img/portfolio/fukushima/kotoba-fukushima1.jpg'
import fukushimaImg3 from '../assets/img/portfolio/fukushima/kotoba-fukushima2.jpg'
import fukushimaImg4 from '../assets/img/portfolio/fukushima/kotoba-fukushima3.jpg'

import amnestyImg1 from '../assets/img/portfolio/min/amnesty/amnestystories.jpg'
import amnestyImg2 from '../assets/img/portfolio/amnesty/amst-s_home.PNG'
import amnestyImg3 from '../assets/img/portfolio/amnesty/amst-s_data.PNG'
import amnestyImg4 from '../assets/img/portfolio/amnesty/amst-s_data3.PNG'
import amnestyImg5 from '../assets/img/portfolio/amnesty/amst-s_data2.PNG'


import rte1 from '../assets/img/portfolio/tempete/rte_cover.JPG'
import rte2 from '../assets/img/portfolio/tempete/rte_data.JPG'

import ffhbImg1 from '../assets/img/portfolio/min/ffhb/commeunemaisonbleue.jpg'
import ffhbImg2 from '../assets/img/portfolio/ffhb/commeunemaisonbleue0.jpg'

import pbc1 from '../assets/img/portfolio/min/pbc/prixbayeuxcalvados.jpg'
import pbc2 from '../assets/img/portfolio/pbc/prixbayeuxcalvados.jpg'
import pbc3 from '../assets/img/portfolio/pbc/prixbayeuxcalvados1.jpg'

import euImg1 from '../assets/img/portfolio/min/europe/bonplaneu.jpg'
import euImg2 from '../assets/img/portfolio/europe/bonplaneu1.jpg'
import euImg3 from '../assets/img/portfolio/europe/bonplaneu0.jpg'
import euImg4 from '../assets/img/portfolio/europe/bonplaneu2.jpg'

import spnpImg1 from '../assets/img/portfolio/min/telfrance/studiopostnprod.jpg'

const PortfolioData = [
    {
        name:"Kotoba, dialogues à Fukushima",
        client:"IRSN",
        url:"http://fukushima-dialogues.com",
        imgMin:fukushimaImgMin,
        imgUrl:[fukushimaImg1,fukushimaImg2,fukushimaImg3,fukushimaImg4],
        imgAlt:"Paysage aux alentours de Fukushima",
        description:"Série d'entretiens réalisés entre 2015 et 2016 autour de la catastrophe de la centrale nucléaire Tepco, à Fukushima",
        techs:["HTML","CSS", "JS", "Wordpress"]
    },
    {
        name:"Drones : L'autre Guerre",
        client:"Amnesty",
        url:"http://stories.amnesty.fr",
        imgMin:amnestyImg1,
        imgUrl:[amnestyImg1,amnestyImg2,amnestyImg3,amnestyImg4,amnestyImg5],
        imgAlt:"Victime de drones du Pakistan",
        description:"Drones : L'autre Guerre, enquête d'Amnesty International sur ces armes qui abolissent la distance",
        techs:["HTML","CSS", "JS"]
    },
    {
        name:"L'Héritage de la tempête",
        client:"RTE",
        url:"http://rte-france.com/l-heritage-de-la-tempete",
        imgMin:rte1,
        imgUrl:[rte1,rte2],
        imgAlt:"Pylône électrique et ciel bleu",
        description:"Récits et retours d'expérience de RTE sur la tempête de Décembre 1999",
        techs:["HTML","CSS","JS"]
    },
    {
        name:"Olga sur la piste du plan Jüncker",
        client:"UE",
        url:"http://bonplan-eu.eu",
        imgMin:euImg1,
        imgUrl:[euImg1, euImg2, euImg3, euImg4],
        imgAlt:"Vue de l'entreprise Muqans, à Bordeaux",
        description:"Récit du parcours de la photographe Olga Kravets, autour de projets soutenues par l'Union Européenne",
        techs:["HTML","CSS","JS"]
    },
    {
        name:"Un an dans l'intimité des experts",
        client:"FFHB",
        url:"http://comme-une-maison-bleue.com",
        imgMin:ffhbImg1,
        imgUrl:[ffhbImg1, ffhbImg2],
        imgAlt:"Terrain de Handball vu de dessus",
        description:"Série documentaire sur l'équipe de France masculine de Handball",
        techs:["JS", "Racontr"]
    },
    ,
    {
        name:"",
        client:"Prix Bayeux Calvados-Normandie",
        url:"http://prixbayeux.org",
        imgMin:pbc1,
        imgUrl:[pbc1, pbc2, pbc3],
        imgAlt:"Page d'accueil du site prixbayeux.org",
        description:"Présentation du prix Bayeux Calvados-Normandie pour les correspondants de guerre",
        techs:["HTML","CSS","JS","Wordpress"]
    },
    {
        name:"",
        client:"Studios Post & Prod",
        url:"http://studiospostprod.com",
        imgMin:spnpImg1,
        imgUrl:[spnpImg1],
        imgAlt:"Page d'accueil du site studiopostandprod.com",
        description:"Présentation de l'activité des studios Post&Prod",
        techs:["HTML","CSS","JS","Wordpress"]
    }
]

export default PortfolioData